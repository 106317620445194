import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import classnames from 'classnames';
import Swal from 'sweetalert2';
import TextFieldGroup from '../common/TextFieldGroup';
import NavBarDiv from './NavBar';
import { Card } from '../Card/Card';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const longToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 9000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
const ReportPage = () => {
  const [Direction, setDirection] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateUntil, setDateUntil] = useState('');

  async function getReport(e) {
    e.preventDefault();
    const data = {
      Direction: Direction,
      dateFrom: dateFrom,
      dateUntil: dateUntil
    }
    if (dateFrom === '' || dateUntil === '') {
      Toast.fire({
        icon: 'error',
        title: 'Vremenski opseg nije pravilno unet!'
      })
    } else {
      if (Direction === '') {
        Toast.fire({
          icon: 'error',
          title: 'Smer dokumenta nije izabran!'
        })
      } else {
        longToast.fire({
          icon: 'success',
          title: 'Izveštaj se generiše, potrebno vreme za generisanje zavisi od broja faktura i dostupnosti SEF-a, izvestaj ćete dobiti na mail, nemojte koristiti aplikaciju dok ne dobijete izveštaj'
        })
        await axios.post(`/api/invoices/something/`, data)

      }
    }


  }
  return (
    <div className="content" >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Row>
          <Col>
            <Card
              title='Generisanje izveštaja o fakturama na SEF-u'
              content={
                <form onSubmit={(e) => getReport(e)}>
                  <Row>
                    <Col>
                      <TextFieldGroup
                        label="Datum od"
                        type="date"
                        name="DateFrom"
                        value={dateFrom}
                        onChange={(e) => setDateFrom(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <TextFieldGroup
                        label="Datum do"
                        type="date"
                        name="DateUntil"
                        value={dateUntil}
                        onChange={(e) => setDateUntil(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Col>
                    <div className="col-md-6" style={{ margin: 'auto' }}>
                      <label className="control-label">Smer dokumenta</label>
                      <select className={classnames('form-control')}
                        name="Direction"
                        value={Direction}
                        onChange={(e) => setDirection(e.target.value)}
                      >
                        <option value="">Izaberite smer dokumenta</option>
                        <option value="purchase">Ulazni</option>
                        <option value="sales">Izlazni</option>
                      </select>
                    </div>
                    <input
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}
                      type="submit"
                      value="Pretraga"
                      className="btn btn-info btn-fill btn-block mt-4"
                    />
                  </Col>
                </form>
              } />
          </Col >
        </Row >

      </div>
      {< NavBarDiv />}
    </div >
  )
}

export default ReportPage;