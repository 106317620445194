import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      position: this.state.position
    };

    this.props.registerUser(newUser, this.props.history);
  }

  componentDidMount() {
    if (this.props.auth.user.position !== 'Admin') {
      this.props.history.push('/clients');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    //const { services } = this.props.services;
    //const optionsService = services.map(services => ({
    //  label: services.name,
    //  value: services._id
    //}));
    //optionsService.unshift({ label: '* Selektujte servis', value: '' });
    
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title="Registracija Admin/Korisnika"
                category="Kreiranje Admin/Korisnika"
                content={
                  <div>
                    <form noValidate onSubmit={this.onSubmit}>
                      <TextFieldGroup
                        placeholder="Ime i Prezime"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        error={errors.name}
                      />
                      <TextFieldGroup
                        placeholder="Email Adresa"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors.email}
                      />
                      <TextFieldGroup
                        placeholder="Password"
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                      />
                      <TextFieldGroup
                        placeholder="Confirm Password"
                        name="password2"
                        type="password"
                        value={this.state.password2}
                        onChange={this.onChange}
                        error={errors.password2}
                      />
                      <div className="form-group">
                        <select
                          className={classnames(
                            'form-control form-control-lg',
                            {
                              'is-invalid': errors.position
                            }
                          )}
                          name="position"
                          value={this.state.position}
                          onChange={this.onChange}
                        >
                          <option value="">* Select Position</option>
                          <option value="PM">Korisnik</option>
                          <option value="Admin">Admin</option>
                        </select>
                        {errors.position && (
                          <div className="invalid-feedback">
                            {errors.position}
                          </div>
                        )}
                      </div>
                      <input
                        type="submit"
                        value="Registruj"
                        className="btn btn-fill btn-info btn-block mt-4"
                      />
                    </form>
                  </div>
                }
              />
            </Col>
            {/*<Col md={6}>
              <Card
                title="Registracija korisnika"
                category="Kreiranje korisnika"
                content={
                  <div>
                    <RegisterClient />
                  </div>
                }
              />
            </Col>*/}
          </Row>
        </Container>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
