const adminRoutes = [
  { path: '/OtpremljeneFakture', name: 'Otpremljene fakture', icon: 'pe-7s-up-arrow' },
  { path: '/PretragaFaktura', name: 'Filtriranje faktura', icon: 'pe-7s-filter' },
  { path: '/PrimljeneFakture', name: 'Primljene fakture', icon: 'pe-7s-bottom-arrow' },
  { path: '/Izvestaji', name: 'Izvestaji', icon: 'pe-7s-file' },
  { path: '/Podesavanja', name: 'Podesavanja', icon: 'pe-7s-config' },
  { redirect: true, path: '/UspesnoSlanje', to: '/UspesnoSlanje', name: 'Uspesno slanje'},
  { redirect: true, path: '/NeuspesnoSlanje', to: '/NeuspesnoSlanje', name: 'Neuspesno slanje'},
  { redirect: true, path: '/', to: '/login', name: 'Login' }
];

export default adminRoutes;
