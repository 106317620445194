import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

class Footer extends Component {
  render() {
    const authLinks =
      this.props.auth.user.position !== 'Klijent' ? (
        <footer className="footer">
          <Container fluid>
            <p className="copyright pull-right">
              &copy; {new Date().getFullYear()}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.galeb.com"
              >
                Galeb Electronics
              </a>
                - E fakture.
            </p>
          </Container>
        </footer>
      ) : (
        <footer className="footer">
          <Container fluid>
            <p className="copyright pull-right">
              &copy; {new Date().getFullYear()}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.galeb.com"
              >
                Galeb Electronics
              </a>
                - E fakture.
            </p>
          </Container>
        </footer>
      );
    return authLinks;
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Footer);
