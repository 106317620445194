import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const SuccessfulApiCall = () => {
  Toast.fire({
    icon: 'success',
    title: 'Faktura je uspešno poslata na SEF'
  })

  return (<div className='content'></div>);
}

export default SuccessfulApiCall