import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import Swal from 'sweetalert2';

export default function AccordionDisplay(props) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  async function sendMailToAccountant(id, direction) {
    const payload = {
      invoiceId: id,
      direction: direction
    }
    await axios.post(`/api/invoices/sendInvoiceThroughMail/`, payload)
      .then(resp => {
        console.log(resp)
        Toast.fire({
          icon: 'success',
          title: 'Faktura je uspešno prosleđena'
        })
      })
      .catch(err => {
        Toast.fire({
          icon: 'error',
          title: err.response.data.error
        })
      })
  }


  async function downloadAsPDF(id, direction) {
    const data = {
      invoiceId: id,
      direction: direction
    }
    Swal.fire({
      title: 'Molim sačekajte...',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    await axios.post(`/api/invoices/donwloadAttachments/`, data)
      .then(async response => {
        let datav2 = {};
        let fileName;
        const dataArray = response.data;
        dataArray.forEach(element => {
          const content = element.content
          const filename = element.filename
          datav2[content] = filename
        });
        const inputOptions = datav2
        const { value: name } = await Swal.fire({
          title: "Izaberite dokument za preuzimanje:",
          input: "radio",
          inputOptions,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Odustani',
          inputValidator: (value) => {
            fileName = inputOptions[value]
            if (!value) {
              return "Morate odabrati jedan dokument!";
            }
          }
        });
        if (name) {
          let base64String = name
          base64String = "data:application/pdf;base64," + base64String;
          downloadFileObject(base64String, fileName);
        }
      })
      .catch(err => {
        Toast.fire({
          icon: 'error',
          title: 'Problem prilikom izvršavanja!'
        })
      })



    /*
     await axios.get(`/api/invoices/getPdfData/${id}/${direction}`)
       .then(data => {
 
         let base64String = data.data
         base64String = "data:application/pdf;base64," + base64String;
         downloadFileObject(base64String, faktura);
 
       })
       .catch(err => {
         Toast.fire({
           icon: 'error',
           title: err.response.data.error
         })
       })
    */
  }

  function downloadFileObject(base64String, faktura) {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = `${faktura}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  /*
    async function onCancelInvoice(salesId) {
      const CancelInvoice = async (salesId) => {
   
        const { value: comment } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Razlog za otkazivanje',
          inputPlaceholder: 'Unesite razlog za otkazivanje fakture...',
          inputAttributes: {
            'aria-label': 'Unesite razlog za otkazivanje fakture'
          },
          showCancelButton: true
        })
        const data = {
          invoiceId: salesId,
          rejectReason: comment
        }
        console.log(data)
        axios.post(`/api/invoices/resendMistakeInvoice/`, data)
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
   
      }
      CancelInvoice(salesId)
    }
  */
  async function onAcceptInvoice(PurchaseId) {
    Swal.fire({
      title: 'Prihvatanje dokumenta',
      text: "Da li ste sigurni da želite da prihvatite dokument",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Prihvati dokument'
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const getAcceptInvoice = async (PurchaseId) => {
            axios.get(`/api/invoices/AcceptInvoice/${PurchaseId}`)
              .then(res => {
                console.log(res.data)
                Toast.fire({
                  icon: 'success',
                  title: 'Dokument je prihvaćen '
                })
                setTimeout(function () {
                  window.location.reload()
                }, 3000);
              })
              .catch(err => {
                Toast.fire({
                  icon: 'error',
                  title: err.response.data.errorSend
                })
              })
          }
          getAcceptInvoice(PurchaseId)
        }
      })
  }

  async function onDeclineInvoice(id) {
    Swal.fire({
      title: 'Odbijanje dokumenta',
      text: "Da li ste sigurni da želite da odbijete dokument",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Odbij dokument'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: reason } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Komentar',
          inputPlaceholder: 'Razlog za odbijanje dokumenta...',
          inputAttributes: {
            'aria-label': 'Unesite razlog za odbijanje dokumenta'
          },
          showCancelButton: true
        })
        if (reason) {
          const getDeclineInvoice = async (invoiceId, reason) => {
            axios.get(`/api/invoices/DeclineInvoice/${invoiceId}/${reason}`)
              .then(res => {
                Toast.fire({
                  icon: 'success',
                  title: 'Dokument je odbijen '
                })
                setTimeout(function () {
                  window.location.reload()
                }, 3000);
              })
              .catch(err => {
                Toast.fire({
                  icon: 'error',
                  title: err.response.data.errorSend
                })
              })
          }
          getDeclineInvoice(id, reason)
        }
      }
    })
  }

  async function onStornoInvoice(invoiceId, stornoNumber) {
    Swal.fire({
      title: 'Storniranje dokumenta',
      text: "Da li ste sigurni da želite da stornirate dokument",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Storniraj dokument'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: comment } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Razlog za storniranje',
          inputPlaceholder: 'Unesite razlog za storniranje fakture...',
          inputAttributes: {
            'aria-label': 'Unesite razlog za storniranje fakture'
          },
          showCancelButton: true
        })
        if (comment) {
          const invoiceData = {
            invoiceID: invoiceId,
            stornoComment: comment,
            stornoNumber: stornoNumber
          }
          const onStornoInvoice = async () => {
            axios.post(`/api/invoices/stornoInvoice/`, invoiceData)
              .then(res => {
                Toast.fire({
                  icon: 'success',
                  title: 'Dokument je storniran'
                })
                setTimeout(function () {
                  window.location.reload()
                }, 3000);
              })
              .catch(err => {
                Toast.fire({
                  icon: 'error',
                  title: err.response.data.errorSend
                })
              })
          }
          onStornoInvoice()
        }
      }
    }
    )
  }

  function SwitchStatus(status) {
    switch (status) {
      case 'New':
        return 'Nova';
      case 'Draft':
        return 'Nacrt';
      case 'Sent':
        return 'Poslata';
      case 'Mistake':
        return 'Greska';
      case 'Sending':
        return 'Slanje';
      case 'Approved':
        return 'Odobrena';
      case 'Rejected':
        return 'Odbijena';
      case 'Cancelled':
        return 'Otkazana';
      case 'Storno':
        return 'Stornirana';
      case 'Seen':
        return 'Vidjena';
      default:
        return null;
    }
  }

  function SwitchStatusSign(status) {
    switch (status) {
      case 'Mistake':
        return <i className='pe-7s-less' style={{ color: 'red', fontSize: '20px' }} ></i>
      case 'Approved':
        return <i className='pe-7s-check' style={{ color: 'green', fontSize: '20px' }} > </i>;
      case 'Rejected':
        return <i className='pe-7s-close-circle' style={{ color: 'red', fontSize: '20px' }} > </i>;
      case 'Storno':
        return <i className='pe-7s-attention' style={{ color: 'orange', fontSize: '20px' }} > </i>;
      default:
        return <i className='pe-7s-info' style={{ color: 'gray', fontSize: '20px' }} > </i>;
    }
  }

  const AccordionDisplay = (props) => {
    const { dataSet } = props;

    if (dataSet.length > 0) {
      return (
        dataSet.map((data) => {
          return (
            <tr key={data.id}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item>
                  <Accordion.Header>
                    {SwitchStatusSign(data.Status)}<div>{data.Ime}</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <tr>
                      <td style={{ wordWrap: 'break-word', width: '20%' }}>Status: </td><td>{SwitchStatus(data.Status)}</td>
                    </tr>
                    <tr>
                      <td style={{ wordWrap: 'break-word', width: '20%' }}>Faktura: </td><td>{data.Faktura}</td>
                    </tr>
                    <tr>
                      <td style={{ wordWrap: 'break-word', width: '20%' }}>Komentar: </td><td>{data.Komentar}{data.SKomentar}{data.CKomentar}</td>
                    </tr>
                    <tr>
                      <td style={{ wordWrap: 'break-word', width: '20%' }}>Tip: </td><td>{data.Tip}</td>
                    </tr>
                    <tr>
                      <td style={{ wordWrap: 'break-word', width: '20%' }}>Datum: </td><td>{data.Datum_slanja}</td>
                    </tr>
                    <tr>
                      <td style={{ wordWrap: 'break-word', width: '20%', verticalAlign: 'middle' }}>Akcije: </td>
                      {data.Smer === 'Izlazna' ? (
                        <td className='d-flex justify-content-between'>
                          {/*data.Status === 'Mistake' ? <button
                            type="button"
                            style={{ borderRadius: '30px' }}
                            className="btn-fill btn btn-sm btn-danger"
                            onClick={onCancelInvoice.bind(this, data.id)}
                          >
                            <i className='pe-7s-back-2' style={{ fontSize: '20px' }}></i>
                          </button> : (<span> </span>)*/}
                          {data.Status !== 'Storno' ? data.Status !== 'Mistake' ?
                            <button
                              type="button"
                              style={{ borderRadius: '30px' }}
                              className="btn-fill btn btn-sm btn-warning"
                              onClick={onStornoInvoice.bind(this, data.id, data.Faktura)}
                            >
                              <i className='pe-7s-attention' style={{ fontSize: '20px' }}></i>
                            </button> : (<span> </span>) : (<span> </span>)}
                          <button
                            type='button'
                            style={{ background: 'gray', borderRadius: '30px' }}
                            className="btn-fill btn btn-sm"
                            onClick={downloadAsPDF.bind(this, data.id, data.Smer)}
                          >
                            <i className='pe-7s-look' style={{ fontSize: '20px' }}></i>
                          </button>
                          <button
                            type='button'
                            style={{ borderRadius: '30px' }}
                            className='btn-fill btn btn-sm btn-info'
                            onClick={sendMailToAccountant.bind(this, data.id, data.Smer)}>
                            <i className='pe-7s-mail' style={{ fontSize: '20px' }}></i>
                          </button>
                        </td>) : (
                        <td className='d-flex justify-content-between'>
                          {data.Status !== 'Approved' ? data.Status !== 'Storno' ? data.Status !== 'Mistake' ?
                            <button
                              type="button"
                              style={{ borderRadius: '30px' }}
                              className="btn-fill btn btn-sm btn-success"
                              onClick={onAcceptInvoice.bind(this, data.id)}
                            >
                              <i className='pe-7s-check' style={{ fontSize: '20px' }}></i>
                            </button> : (<span> </span>) : (<span> </span>) : (<span> </span>)
                          }

                          {data.Status !== 'Approved' ? data.Status !== 'Rejected' ? data.Status !== 'Storno' ? data.Status !== 'Mistake' ?
                            <button
                              type="button"
                              className="btn-fill btn btn-sm btn-danger"
                              style={{ borderRadius: '30px' }}
                              onClick={onDeclineInvoice.bind(this, data.id)}
                            >
                              <i className='pe-7s-close' style={{ fontSize: '20px' }}></i>
                            </button> : (<span> </span>) : (<span> </span>) : (<span> </span>) : (<span> </span>)
                          }
                          <button
                            type='button'
                            className="btn-fill btn btn-sm"
                            style={{ background: 'gray', borderRadius: '30px' }}
                            onClick={downloadAsPDF.bind(this, data.id, data.Smer)}
                          >
                            <i className='pe-7s-look' style={{ fontSize: '20px' }}></i>
                          </button>
                          <button
                            type='button'
                            style={{ borderRadius: '30px' }}
                            className='btn-fill btn btn-sm btn-info'
                            onClick={sendMailToAccountant.bind(this, data.id, data.Smer)}>
                            <i className='pe-7s-mail' style={{ fontSize: '20px' }}></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </tr >
          )
        })
      )
    }
  }
  return (
    <>
      {AccordionDisplay(props)}
    </>
  )
}