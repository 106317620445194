import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import classnames from 'classnames';
import Swal from 'sweetalert2';
import { Card } from '../Card/Card';
import TextFieldGroup from '../common/TextFieldGroup';
import AccordionDisplay from './AccordionDisplay';
import NavBarDiv from './NavBar';

function wait(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

var isMounted = true;
var InvoiceData = [];

const SearchInvoicesPage = () => {
  const [, setDataInv] = useState('')
  const [dateFrom, setDateFrom] = useState('');
  const [dateUntil, setDateUntil] = useState('');
  const [Status, setStatus] = useState('');
  const [direction, setDirection] = useState('');
  const [isDisabled, setIsDisabled] = useState(false)



  useEffect(() => {
    return () => {
      isMounted = false;
    }
  }, [])

  async function onSubmit(e) {

    e.preventDefault();
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 1000)

    const payload = {
      DateFrom: dateFrom,
      DateUntil: dateUntil,
      Status: Status,
      direction: direction,
    }
    console.log(payload)

    InvoiceData = []
    if (dateFrom === '' || dateUntil === '') {
      Toast.fire({
        icon: 'error',
        title: 'Vremenski opseg nije pravilno unet!'
      })
    } else {
      if (direction === '') {
        Toast.fire({
          icon: 'error',
          title: 'Smer dokumenta nije izabran!'
        })
      } else {
        const doMagic = async () => {
          const getData = async () => {
            const idsData = await axios.post(`/api/invoices/getIds/`, payload)
            return idsData.data
          };
          const getInvoiceData = async (dataIds) => {
            for (let i = 0; i < dataIds.length; i++) {
              if (isMounted === false) {
                break;
              }
              await wait(500)
              await axios.post(`/api/invoices/getXmlData/${dataIds[i]}`, payload)
                .then(data => {
                  InvoiceData.push(data.data[0]);
                  setDataInv({}, [])
                })
                .catch(err => {
                  console.log('error')
                })

            }
            console.log(InvoiceData)
            return null;
          }
          let dataIds = await getData()
            .catch(console.error)
          await getInvoiceData(dataIds)
        }
        doMagic();

      }
    }
  }

  return (
    <div className="content">
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Row>
          <Card
            title='Filtrirana pretraga faktura'
            content={
              <form onSubmit={(e) => onSubmit(e)}>
                <Row>
                  <div style={{ width: '50%' }}>
                    <TextFieldGroup
                      label="Datum od"
                      type="date"
                      name="DateFrom"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextFieldGroup
                      label="Datum do"
                      type="date"
                      name="DateUntil"
                      value={dateUntil}
                      onChange={(e) => setDateUntil(e.target.value)}
                    />
                  </div>
                </Row>
                <Col>
                  <div className="col-md-6" style={{ margin: 'auto' }}>
                    <label className="control-label">Status</label>
                    <select className={classnames('form-control')}
                      name="Status"
                      value={Status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Sve</option>
                      <option value="New">Nove</option>
                      <option value="Draft">Nacrti</option>
                      <option value="Sent">Poslate</option>
                      <option value="Mistake">Greske</option>
                      <option value="Sending">Slanje</option>
                      <option value="Approved">Odobrene</option>
                      <option value="Rejected">Odbijene</option>
                      <option value="Cancelled">Otkazane</option>
                      <option value="Storno">Storno</option>
                      <option value="Seen">Vidjeno</option>
                    </select>
                  </div>
                  <div className="col-md-6" style={{ margin: 'auto' }}>
                    <label className="control-label">Smer dokumenta</label>
                    <select className={classnames('form-control')}
                      name="direction"
                      value={direction}
                      onChange={(e) => setDirection(e.target.value)}
                    >
                      <option value="">Izaberite smer dokumenta</option>
                      <option value="Purchase">Ulazni</option>
                      <option value="Sales">Izlazni</option>
                    </select>
                  </div>
                  <input
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}
                    type="submit"
                    value="Pretraga"
                    disabled={isDisabled}
                    className="btn btn-info btn-fill btn-block mt-4"
                  />
                </Col>
              </form>
            }
          />
        </Row>
      </div>
      <Row>
        {<AccordionDisplay dataSet={InvoiceData} />}
      </Row>
      {<NavBarDiv />}
    </div>
  );
}



export default SearchInvoicesPage;