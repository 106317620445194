import React, { useState } from 'react';

export default function NavBarDiv() {

  const [screenWidth] = useState(window.innerWidth)

  const showNavBar = (
    <div style={{
      position: 'sticky',
      bottom: '10%',
      fontSize: '20px',
      zIndex: '1000',
      width: 'max-content',
      borderColor: '#aaaaaa',
      margin: 'auto',
      border: 'none',
      background: '#f7f7f800',
    }}
    >
      <div className='d-flex justify-content-space-between'>
        <a href="/PrimljeneFakture"><i className="pe-7s-bottom-arrow" style={{ fontSize: '45px' }}></i></a>
        <a href="/PretragaFaktura"><i className="pe-7s-filter pe-lg pe-va" style={{ fontSize: '45px' }}></i></a>
        <a href="/OtpremljeneFakture"><i className="pe-7s-up-arrow" style={{ fontSize: '45px' }}></i></a>
        <a href="/Izvestaji"><i className="pe-7s-file" style={{ fontSize: '45px' }}></i></a>
        <a href="/Podesavanja"><i className="pe-7s-config" style={{ fontSize: '45px' }}></i></a>
      </div>
    </div>
  )
  const hideNavBar = (
    <span> </span>
  )
  return (
    showNavBar
  )
}