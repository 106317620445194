import {
  INVOICES_LOADING,
  GET_SENT_INVOICES,
  GET_RECEIVED_INVOICES,
  GET_FILTERED_DATA,
  INVOICE_PDF_DATA,
  INVOICES_STOP_LOADING,
} from '../actions/types';

const initialState = {
  loading: false,
  invoice: {},
  pdfData: {},
  sentInvoices: [],
  receivedInvoices: [],
  filteredInvoices: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INVOICE_PDF_DATA:
      return {
        ...state,
        pdfData: action.payload,
        loading: false
      };
    case GET_FILTERED_DATA:
      return {
        ...state,
        filteredInvoices: action.payload,
        loading: false,
      };
    case GET_SENT_INVOICES:
      return {
        ...state,
        sentInvoices: action.payload,
        loading: false
      };
    case GET_RECEIVED_INVOICES:
      return {
        ...state,
        receivedInvoices: action.payload,
        loading: false
      };
    case INVOICES_LOADING:
      return {
        ...state,
        loading: true
      };
    case INVOICES_STOP_LOADING: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}