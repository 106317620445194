export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILES_LOADING = 'PROFILES_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const ADD_CLIENT = 'ADD_CLIENT';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_DELETED_CLIENTS = 'GET_DELETED_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const CLIENTS_LOADING = 'CLIENTS_LOADING';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_DEVICES = 'GET_DEVICES';
export const GET_DELETED_DEVICES = 'GET_DELETED_DEVICES';
export const DEVICES_LOADING = 'DEVICES_LOADING';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_DELETED_SERVICES = 'GET_DELETED_SERVICES';
export const SERVICES_LOADING = 'SERVICES_LOADING';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKET = 'GET_TICKET';
export const TICKETS_LOADING = 'TICKETS_LOADING';
export const DELETE_TICKET = 'DELETE_TICKET';
export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_REQUEST = 'GET_REQUEST';
export const REQUESTS_LOADING = 'REQUESTS_LOADING';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const INDIVIDUAL_VAT = 'INDIVIDUAL_VAT';
export const GET_RECEIVED_INVOICES = 'GET_RECEIVED_INVOICES';
export const GET_SENT_INVOICES = 'GET_SENT_INVOICES';
export const GET_FILTERED_DATA = 'GET_FILTERED_DATA';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_STOP_LOADING = 'INVOICES_STOP_LOADING';
export const INVOICE_PDF_DATA = 'INVOICE_PDF_DATA';