import {
  ADD_CLIENT,
  INDIVIDUAL_VAT,
  GET_CLIENTS,
  GET_CLIENT,
  CLIENTS_LOADING,
  DELETE_CLIENT,
  GET_DELETED_CLIENTS
} from '../actions/types';

const initialState = {
  clients: [],
  individualVat: [],
  deletedclients: [],
  client: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INDIVIDUAL_VAT:
      return {
        ...state,
        individualVat: action.payload,
        loading: false
      }
    case CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        loading: false
      };
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };
    case GET_DELETED_CLIENTS:
      return {
        ...state,
        deletedclients: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
