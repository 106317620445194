import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import AccordionDisplay from './AccordionDisplay';
import NavBarDiv from './NavBar';
import { Card } from '../Card/Card';

var SalesInvoiceData = [];

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const SentInvoicesPage = () => {
  const [, setDataInv] = useState('');

  function wait(time) {
    return new Promise(resolve => setTimeout(resolve, time))
  }

  useEffect(() => {
    let isMounted = true;
    SalesInvoiceData = [];
    const payload = {
      direction: 'Sales'
    }
    const doMagic = async () => {
      const getData = async () => {
        let dataFromApi = []
        await axios.post(`/api/invoices/getIds/`, payload)
          .then(data => {
            dataFromApi = data.data
          })
          .catch(error => {
            Toast.fire({
              icon: 'error',
              title: error.response.data.error
            })
          })
        return dataFromApi
      };
      const getInvoiceData = async (dataIds) => {
        if (dataIds) {
          for (const id of dataIds) {
          if (isMounted === false) { break; }
            await wait(500)
            await axios.post(`/api/invoices/getXmlData/${id}`, payload)
              .then(data => {
                SalesInvoiceData.push(data.data[0]);
                setDataInv({}, [])
              })
              .catch(err => {
                console.log(err)
              })
          }
        } else {
          console.log('greska')
        }
        return null;
      }
      await getData()
        .then(async res => {
          await getInvoiceData(res)
        })
        .catch(console.error)

    }

    doMagic();

    return () => {
      isMounted = false;
    }

  }, [])

  return (
    <div className="content">
      <Row>
        <Card title='Prodajne fakture' />
        {<AccordionDisplay dataSet={SalesInvoiceData} />}
      </Row>
      {<NavBarDiv />}
    </div>
  );
}

export default SentInvoicesPage;
