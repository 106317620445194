import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.user.position !== undefined) {
      if (this.props.auth.user.position === 'Admin') {
        this.props.history.push('/PrimljeneFakture')
      } else if (this.props.auth.user.position === 'user') {
        this.props.history.push('/PrimljeneFakture');
      } else { console.log('error') }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      console.log(this.props.auth)
      if (this.props.auth.user.position !== undefined) {
        if (this.props.auth.user.oupToken === 1) {
          this.props.history.push('/zaboravljenaLozinka')
        } else {
          if (this.props.auth.user.position === 'Admin') {
            this.props.history.push('/PrimljeneFakture')
          } else if (this.props.auth.user.position === 'user') {
            this.props.history.push('/PrimljeneFakture');
          } else { console.log('error') }
        }

      }
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  async resPass() {
    const payload = {
      email: this.state.email
    }
    await axios.post(`/api/AppUsersLogin/passRes/`, payload)
      .then(res => {
        Toast.fire({
          icon: 'success',
          title: 'Mail sa podacima za resetovanje lozinke je poslat!'
        })
      })
      .catch(err =>{
        Toast.fire({
          icon: 'error',
          title: 'Greska'
        })
      })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onSubmit(e) {

    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="content">
        <Container fluid>
          <Row style={{ paddingTop: '5%' }}>
            <Col md={5} className="pull-center">
              <Card
                content={
                  <div>
                    <h1 className="display-4 text-center">Login</h1>
                    <p className="lead text-center">
                      Ulogujte sa na svoj Galeb E fakture nalog
                    </p>
                    <form noValidate onSubmit={this.onSubmit}>
                      <TextFieldGroup
                        placeholder="Email Adresa"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors.email}
                      />

                      <TextFieldGroup
                        placeholder="Password"
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                      />
                      <Row>
                        <Col>
                          <input
                            type="submit"
                            style={{ width: '120px' }}
                            value="Login"
                            className="btn btn-info btn-fill btn-block mt-4"
                          />
                          <input
                            style={{ float: 'right' }}
                            type='button'
                            value='Reset lozinke'
                            onClick={this.resPass.bind(this)}
                            className='btn btn-info btn-fill btn-block mt-4'
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
