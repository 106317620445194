import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import clientReducer from './clientReducer';
import invoiceReducer from './invoiceReducer';

export default combineReducers({
  invoice: invoiceReducer,
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  client: clientReducer,
});
