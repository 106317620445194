import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileAction';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

class zaboravljenaLozinka extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      newPassword: '',
      newConfPassword: '',
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ email: this.props.auth.user.loginMail })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onSubmit(e) {

    e.preventDefault();
    if (this.state.newConfPassword !== this.state.newPassword) {
      Toast.fire({
        icon: 'error',
        title: 'Lozinke se razlikuju!'
      })
    } else {
      const userData = {
        email: this.state.email,
        password: this.state.password,
        newPassword: this.state.newPassword,
      };
      await axios.post(`/api/AppUsersLogin/changePassUsingOUP/`, userData)
        .then(res => {
          e.preventDefault();
          this.props.clearCurrentProfile();
          this.props.logoutUser();
        })
        .catch(err => {
          Toast.fire({
            icon: 'error',
            title: 'Greska!'
          })
        })

    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row style={{ paddingTop: '5%' }}>
            <Col md={5} className="pull-center">
              <Card
                content={
                  <div>
                    <h1 className="display-4 text-center">Izmena lozinke</h1>
                    <p className="lead text-center">
                      Promenite lozinku svog galeb E-fakture naloga
                    </p>
                    <label>Login mail</label>
                    <form noValidate onSubmit={this.onSubmit}>
                      <Col>
                        <Row>
                          <TextFieldGroup
                            placeholder="Email Adresa"
                            name="email"
                            type="email"
                            value={this.state.email}
                            disabled={true}
                          />
                        </Row>
                        <Row>
                          <label>Lozinka sa kojom ste se ulogovali</label>
                          <TextFieldGroup
                            placeholder="Stara lozinka"
                            name="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.onChange}
                          />
                        </Row>
                        <Row>
                          <label>Nova lozinka</label>
                          <TextFieldGroup
                            placeholder="Nova lozinka"
                            name="newPassword"
                            type="password"
                            value={this.state.newPassword}
                            onChange={this.onChange}
                          />
                        </Row>
                        <Row>
                          <label>Potvrda lozinke</label>
                          <TextFieldGroup
                            placeholder="Potvrda lozinke"
                            name="newConfPassword"
                            type="password"
                            value={this.state.newConfPassword}
                            onChange={this.onChange}
                          />
                        </Row>
                      </Col>
                      <Row>
                        <Col>
                          <input
                            type="submit"
                            value="Promeni password"
                            className="btn btn-info btn-fill btn-block mt-4"
                          />
                        </Col>
                      </Row>
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

zaboravljenaLozinka.propTypes = {
  loginUser: PropTypes.func.isRequired,
  clearCurrentProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, clearCurrentProfile, logoutUser }
)(zaboravljenaLozinka);
