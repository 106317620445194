import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import store from '../../store';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileAction';
import PrivateRoute from '../../components/common/PrivateRoute';
import Login from '../../components/auth/Login';
import Register from '../../components/auth/Register';
import EditProfile from '../../components/edit-profile/EditProfile';
import ProfileEdit from '../../components/profiles/ProfileEdit';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';

import { style } from '../../variables/Variables.jsx';
import adminRoutes from '../../routes/admin.jsx';
import clientuserRoutes from '../../routes/clientuser';
import dashboardRoutes from '../../routes/dashboard';

import ReceivedInvoicesPage from '../../components/clients/ReceivedInvoicesPage';
import SentInvoicesPage from '../../components/clients/SentInvoicesPage';
import SearchInvoicesPage from '../../components/clients/SearchInvoicesPage';
import ReportPage from '../../components/clients/ReportPage';
import Options from '../../components/clients/Options';
import ZaboravljenaLozinka from '../../components/auth/ZaboravljenaLozinka';
import SuccessfulApiCall from '../../components/ApiRedirectPages/Successful';
import ErrorApiCall from '../../components/ApiRedirectPages/ErrorResponse';

//Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/login';
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null
    };
  }
  handleNotificationClick(position) {
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      //level: level,
      position: position,
      autoDismiss: 15
    });
  }
  componentDidMount() {

  }
  componentDidUpdate(e) {
    if (
      //window.innerWidth < 500 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    let route;
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'Admin') {
        route = adminRoutes;
      } else if (this.props.auth.user.position === 'user') {
        route = clientuserRoutes;
      } else { route = dashboardRoutes }
    } else {
      route = [{ redirect: true, path: '/', to: '/login', name: 'Login' }];
    }
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Route exact path="/login" component={Login} />
          <Switch>
            {route.map((prop, key) => {
              if (prop.name === 'Notifications')
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    render={routeProps => (
                      <prop.component
                        {...routeProps}
                        handleClick={this.handleNotificationClick}
                      />
                    )}
                  />
                );
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Switch>
            <PrivateRoute exact path="/edit-profile" component={EditProfile} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/profiles/:id" component={ProfileEdit} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/register" component={Register} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/PrimljeneFakture" component={ReceivedInvoicesPage} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/OtpremljeneFakture" component={SentInvoicesPage} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/PretragaFaktura" component={SearchInvoicesPage} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/Izvestaji" component={ReportPage} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/Podesavanja' component={Options} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/UspesnoSlanje' component={SuccessfulApiCall } />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/OtpremljeneFakture/NeuspesnoSlanje/:msg' component={ErrorApiCall} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/ZaboravljenaLozinka' component={ZaboravljenaLozinka} />
          </Switch>

          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Dashboard);
