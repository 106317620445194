import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const ErrorApiCall = () => {
  const url = window.location.href;
  const pageUrl = url.substring(0, url.lastIndexOf('/')) + "/"
  const msg = url.replace(pageUrl, "");
  const displayMsg = msg.replace(/%20/g, " ");
  console.log(displayMsg)
  Toast.fire({
    icon: 'error',
    title: displayMsg
  })

  return (<div className='content'></div>);
}

export default ErrorApiCall